@import "../main/abstracts/index";

body[data-template="home"]{

    .section--event-list{

        header{

            h2{

                .subheadline{
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
                    font-weight: $font__weight--normal;
                    color: $color__black;
                    margin-left: 35px;
                }

                @include media-breakpoint-down(md) {

                    .subheadline{
                        margin-left: 0;
                        margin-top: 6px;
                    }

                    span{
                        display: block;
                    }
                }
            }
        }

        .event-list__list{

            .card{

                @include hover-focus-visible{

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                @include hover-focus-within{

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                &:hover,
                &:focus {

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                &--event{
                    border: none;
                    flex-direction: row;
                    flex-wrap: wrap;
                    border-radius: 8px;
                    overflow: hidden;

                    @include media-breakpoint-up(sm) {
                        flex-wrap: nowrap;
                    }

                    @include hover-focus-visible{
                        .card-img-bottom{
                            transform: scale(1.07);
                        }
                    }

                    &.focus-within{

                        .card-img-bottom{
                            transform: scale(1.07);
                        }
                    }
                }

                &-body{
                    width: 100%;
                    flex-direction: initial;
                    flex-wrap: wrap;
                    padding-bottom: 24px;
                    z-index: 1;
                    background-color: $color__gray--light;

                    @include media-breakpoint-up(sm) {
                        width: 50%;
                    }

                    >*{
                        width: 100%;
                    }

                    .card{

                        &-date {
                            position: relative;
                            left: auto;
                            transform: none;
                            min-width: unset;
                            max-width: unset;
                            margin:0;
                            padding: 0;
                            display: block;
                            border:none;
                            background-color: transparent;

                            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 20px, 28px);
                            font-weight: $font-weight-normal;
                            color: $color__primary-2;

                            height: auto;
                            margin-bottom: 15px;

                            @include media-breakpoint-up(lg) {
                                min-height: 66px;
                                margin-bottom: 10px;
                            }

                            &__from,
                            &__to{
                                font-size: inherit;
                                font-weight: $font-weight-normal;
                                line-height: normal;
                                display: inline-block;
                                margin-right: 6px;

                                @include media-breakpoint-up(lg) {
                                    display: block;
                                }
                            }
                        }

                        &-title.equalheight{

                            @include media-breakpoint-down(lg) {
                                height: auto!important;
                            }
                        }

                        &-text{
                            align-self: flex-end;
                            min-height: 118px;
                        }

                        &-line{
                            content: "";
                            position: relative;
                            display: block;
                            height: 2px;
                            width: 95px;
                            margin-top: 25px;
                            margin-bottom: 10px;
                            border-radius: 3px;
                            background-color: $color__black;
                        }

                        &-teaser{
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }

                    > .icon{
                        position: absolute;
                        justify-content: center!important;
                        align-items: center;
                        right: 22px;
                        padding: 0!important;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        z-index: 1;
                        background-color: $color__white;

                        img{
                            height: 30px;
                            width: 30px;
                            filter: $filter__primary-2;
                            transition: transform .2s ease-in-out;
                        }
                    }
                }

                &-img-bottom{
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 250px;
                    object-fit: cover;
                    border-radius: 0;

                    transform: scale(1.01);
                    transition: transform .2s ease-in-out;

                    @media (max-width: 500px) {
                        max-height: 200px;
                    }

                    @include media-breakpoint-up(sm) {
                        max-width: 35%;
                        max-height: unset;
                    }

                    @media (min-width: 1100px) {
                        max-width: 40%;
                    }

                    @include media-breakpoint-up(xxl) {
                        max-width: 50%;
                    }
                }

                .dgf-card-image--empty{
                    display: none;
                }
            }
        }

        .splide:not(.splide--custom){

            .splide__arrow{
                border-color: $color__white;

                &:hover,
                &:focus {
                    border-color: $color__primary;
                }
            }

            .splide__pagination li button{

                &:hover,
                &:focus {

                    &:before{
                        background-color: $color__white;
                        border-color: $color__white;
                    }
                }
            }

            @include media-breakpoint-down(md) {

                .splide__navigation{
                    flex-direction: column;
                }

                .splide__link {
                    margin-top: 2rem;
                }
            }
        }
    }
}
